table {
  border-spacing: 0;
  /* border: 1px solid black; */

}

td {
  margin: 0;
  padding: 0.5rem;
  /* border-right: 1px solid black; */

}

th {
  margin: 0;
  padding: 0.5rem;
  /* border-bottom: 1px solid black; */
  background: #F5F5F5;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
}

.blue-btn {
  background: #6F90BD !important;
  border-color: #6F90BD !important;
}

.blue-outline-btn {
  color: #6F90BD !important;
  background: transparent !important;
  border-color: #6F90BD !important;
}

.red-btn {
  background: red !important;
  border-color: red !important;
}

.red-outline-btn {
  color: red !important;
  background: transparent !important;
  border-color: red !important;
}

.teal-btn {
  background: #00A79D !important;
  border-color: #00A79D !important;
  width: 100%;
}
